var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header-floating"},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[(_vm.$root.user)?_c('v-row',{staticClass:"eyebrow-row ma-0"},[_c('v-col',{staticClass:"text-start align-self-end pa-0 pl-5 white--text",attrs:{"cols":"4"}},[_c('span',{staticClass:"deep-orange--text"},[_vm._v("Sports Betting")]),_vm._v(" + "),_c('span',{staticClass:"amber--text"},[_vm._v("Poker")])]),_c('v-col',{staticClass:"text-end align-self-end pa-0",attrs:{"cols":"8"}},[_c('v-btn-toggle',{staticClass:"eyebrow-menu",attrs:{"background-color":"transparent","color":"white"}},[_vm._l((_vm.$root.navigation.eyebrowMenu),function(item,index){return _c('v-tooltip',{key:index,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","ripple":false,"text":"","color":"white","to":{ name: item.name }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#fff"}},[_vm._v(_vm._s(item.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title))])])}),_c('v-divider',{attrs:{"dark":"","vertical":""}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","ripple":false,"text":"","color":"white","to":"/my-wallet"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#fff"}},[_vm._v("mdi-wallet")])],1),(_vm.getAmount)?_c('span',{staticClass:"amount-output"},[_vm._v("$"+_vm._s(_vm.getAmount.amount))]):_vm._e()]}}],null,false,3524692973)},[_c('span',[_vm._v("Wallet")])]),_c('v-divider',{attrs:{"dark":"","vertical":""}}),(_vm.$root.user)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","ripple":false,"text":"","color":"white","to":"/logout"}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#fff"}},[_vm._v("mdi-logout")])],1)]}}],null,false,1208517260)},[_c('span',[_vm._v("Logout")])]):_vm._e()],2)],1)],1):_vm._e(),_c('v-row',{staticClass:"main-row"},[_c('v-col',{staticClass:"text-center align-self-center",attrs:{"md":"3","sm":"12"}},[_c('router-link',{staticClass:"logo",attrs:{"to":{ name: 'Home' }}},[_c('img',{attrs:{"src":require('@/assets/logo-full-simple.png')}})])],1),_c('v-col',{staticClass:"align-self-center",attrs:{"md":"9","sm":"12"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"text-end justify-center"},[_c('pp-menu-main')],1),(!_vm.$root.user)?_c('v-col',{staticClass:"text-end align-self-center",attrs:{"cols":"3"}},[_c('v-btn',{attrs:{"to":"/register","color":"primary","x-large":""}},[_vm._v("Register Now")])],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }