import Vue from 'vue'
import App from './App.vue'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import VueTour from 'vue-tour'

require('vue-tour/dist/vue-tour.css');

Vue.use(VueTour);

Vue.config.productionTip = false;

import router from './router';
import store from '@/stores';

import './components';

new Vue({
  router,
  store,
  vuetify,
  data () {
    return {
      store:{
        agePrompt: true,
        systemMessage: '',
        showTesting: false,
      },
      snackbar: {
        open: false,
        message: '',
      },
      filterbar:{
        open: false,
        card:{
          values: [0,1,2,3,4,5,6,7,8,9,10,11,12],
          symbols: [0,1,2,3],
        },
        bet:{
          types: [0,1,2],
        },
        sports: [0,1,2,3,4,5],
      },
      navigation:{
        mainAuthMenu:[
          {
            title: 'Dashboard',
            name: 'Dashboard',
            icon: 'mdi-gauge',
          },
          {
            title: 'Bets',
            name: 'Bets',
            icon: 'mdi-cards',
          },
          {
            title: 'Wallet',
            name: 'Wallet',
            icon: 'mdi-wallet',
          },
          {
            title: 'How to Play',
            name: 'HowToPlay',
            icon: 'mdi-help-circle',
          },
          // {
          //   title: 'Profile',
          //   name: 'Profile',
          //   icon: 'mdi-account-circle'
          // },
        ],
        mainNoAuthMenu:[
          {
            title: 'How to Play',
            name: 'HowToPlay',
            icon: 'mdi-help-circle',
          },
          {
            title: 'About Us',
            name: 'About',
            icon: 'mdi-domain',
          },
          {
            title: 'Contact Us',
            name: 'Contact',
            icon: 'mdi-phone',
          },
          {
            title: 'Login',
            name: 'Login',
            icon: 'mdi-lock-open',
          },
        ],
        socialMedia: [
          {
            title: 'Facebook',
            url: 'https://www.facebook.com/Parlay-Poker-103884985500967',
            icon: 'mdi-facebook',
          },
          {
            title: 'Instagram',
            url: '',
            icon: '',
          },
          {
            title: 'Twitter',
            url: 'https://twitter.com/parlaypoker',
            icon: 'mdi-twitter',
          }
        ],
        eyebrowMenu:[
          {
            title: 'Profile',
            name: 'Profile',
            icon: 'mdi-account-circle'
          },
        ]
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods:{
    getStartDate(){
      let today = new Date();
      return today.toLocaleDateString();
    },
    getEndDate(){
      let today = new Date();
      today.setDate(today.getDate() + 7);
      return today.toLocaleDateString();
    },
    checkAuth(user){
      if(user == null){
        // this.navigation.userMenu = this.navigation.userNoAuthMenu;
        this.navigation.mainMenu = this.navigation.mainNoAuthMenu;
      }else{
        // this.navigation.userMenu = this.navigation.userAuthMenu;
        this.navigation.mainMenu = this.navigation.mainAuthMenu;
      }       
    },
  },
  async created(){
    this.checkAuth(this.user);
  },
  watch:{
    'user' (to){
      this.checkAuth(to);
    },
  },
  render: h => h(App),
}).$mount('#app')
