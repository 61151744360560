<template>
  <v-footer class="footer-marketing">
    <v-col class="text-start" cols="8">
      &copy; 2022 Neesan Labs LLC. "Parlay Poker" and the Parlay Poker logo are registered trademarks of Neesan Labs LLC.  All Rights Reserved. <br><v-btn x-small plain text :ripple="false" href="https://www.neesanlabs.com">Neesan Labs</v-btn> | <v-btn x-small plain text :ripple="false" to="/privacy">Privacy Policy</v-btn> | <v-btn x-small plain text :ripple="false" to="/tos">Terms of Use</v-btn> | <v-btn x-small plain text :ripple="false" to="/contact">Contact Us</v-btn>
    </v-col>
    <v-col class="text-end" cols="4">
      <pp-menu-social></pp-menu-social>
    </v-col>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer.vue',
  data(){
    return{

    }
  },
  mounted(){

  },
  methods:{

  }
}
</script>

<style lang="scss" scoped>
  .footer-marketing{
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    font: {
      size: 12px;
    }
    // border-top-left-radius: 20px;
    // border-top-right-radius: 20px;
    .social-menu{
      .v-tab{
        min-width: auto;
      }
    }
  }
</style>
