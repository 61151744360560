<template>
  <div :class="{'build-hand':true, 'scrolled': isSticky}" :style="{'width':railWidth}">
    <v-card elevation="5" dark color="primary" class="rounded-0" v-if="$root.store.showTesting">
      <v-card-title>
        <game-build-hand-tester></game-build-hand-tester>
      </v-card-title>
    </v-card>
    <v-card elevation="2" dark color="#1b1b1b" class="rounded-0">
      <v-card-title class="build-hand-title text-uppercase justify-center">
        Build your hand
      </v-card-title>
      <v-card-text class="px-5 pb-4">
        <v-sheet rounded color="#4CAF50" class="white--text">
          <v-row dense class="ma-0">
            <v-col class="text-center">
              <span class="text-overline">PARLAY</span><br><b v-html="getParlayType"></b><br>
            </v-col>
            <v-divider vertical light></v-divider>
            <v-col class="text-center">
              <span class="text-overline">HAND<game-hand-rank-help></game-hand-rank-help></span><br><b v-html="getPokerType"></b>
            </v-col>
          </v-row>
        </v-sheet>
      </v-card-text>
      <v-card-text class="px-4 pt-0 pb-4" v-if="getHandTotal > 0">
        <game-build-hand-card 
          v-for="(bet, i) in getHand" 
          :key="i"
          :id="i"
          :totalCards="getHandTotal"
          :cardValue="bet.value.toString()"
          :cardSuit="bet.suit"
          :which="bet.which"
          :event="bet.event"
          :team="bet.team"
          :sport="bet.sport"
          :bet="bet.bet"
          short
        >
        </game-build-hand-card>
      </v-card-text>
    </v-card>
    <v-form 
      ref="form"
      v-model="valid"
      lazy-validation>
      <v-card elevation="2" class="ma-4">
        <v-card-subtitle class="text-overline text-center" v-if="isCartReady">Your bet is ready!</v-card-subtitle>
        <v-card-text class="pb-0">
          <v-text-field
            type="number"
            v-model="wager"
            label="Wager"
            placeholder="0"
            :disabled="!isCartReady"
            :rules="wagerRules"
            outlined
            prefix="$"
          >
          </v-text-field>
          <v-text-field
            label="To Win"
            placeholder="0"
            outlined
            prefix="$+"
            readonly
            class="mt-3"
            :value="formattedQty"
            @input="handleQtyInput"
          >
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn block x-large color="primary" light @click="validate" :disabled="!isCartReady">Checkout</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
  import ApiService from "@/services/ApiService.js";
  import StringsService from "@/services/StringsService.js";
  import HandCheck from '@/plugins/handcheck';
  import CanvasConfetti from 'canvas-confetti';

  export default {
    name: 'BuildHand.vue',
    data(){
      return{
        valid: true,
        wager: '',
        toWin: '0',
        isSticky: false,
        wagerRules: [
          v => !!v || 'Wager is required',
          v => (v && v > 0) || 'Wager must be at least $1',
        ],
        railWidth: '',
      }
    },
    computed:{
      formattedQty () {
        let qty = this.toWin + ""
        return qty.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },
      getHand() {
        return this.$store.getters.getHand;
      },
      getHandTotal() {
        return this.$store.getters.getHandTotal;
      },
      getParlayType(){
        switch(this.getHandTotal){
          case 1:
            return 'Two more needed';
          case 2:
            return 'One more needed';
          case 4:
            return 'One more or one less';
          case 3:
          case 5:
            return this.getHandTotal+' Leg Parlay';
          default:
            return 'No cards selected';
        }
      },
      getPokerType(){
        return this.handMessage;
      },
      formattedHand(){
        let hand = this.$store.getters.getHand;
        let formattedHand = [];
        hand.forEach(card => {
          let suit = this.getFormattedSuit(card.suit.name);
          let value = this.getFormattedValue(card.value.toString());
          let formatted = value+suit;
          formattedHand.push(formatted);
        });
        return formattedHand;
      },
      isValidHand(){
        let ret = "";
        if(this.formattedHand.length > 0){
          ret = HandCheck(this.formattedHand).valid;
        }
        return ret;
      },
      handMessage(){
        return HandCheck(this.formattedHand).message;
      },
      isCartReady(){
        return ((this.getHandTotal == 3 || this.getHandTotal == 5) && this.isValidHand);
      },
    },
    mounted(){
      this.onScroll();
    },
    methods:{
      handleQtyInput (newValue) {
        this.toWin = newValue.toString().replace(/\D/g, "")
      },
      validate () {
        let self = this;
        if(this.$refs.form.validate()){
          this.$store
            .dispatch("setBet", {
              hand_label: this.getPokerType,
              parlay_label: this.getParlayType,
              wager: this.wager,
              to_win: this.toWin,
            })
            .then(() => {
                self.$root.snackbar.message = 'Redirecting to checkout!';
                self.$root.snackbar.open = true;
                self.$router.replace('/placebet');
            })
            .catch((err) => {
                self.$root.snackbar.message = err.message;
                self.$root.snackbar.open = true;
            });
          
        }
      },
      onScroll(){
        let self = this;
        window.addEventListener('scroll',() => {
          if(window.scrollY > 160){
            self.isSticky = true;
            let box = document.querySelector('.rail');
            self.railWidth = box.clientWidth+'px';
          }else{
            self.railWidth = '';
            self.isSticky = false;
          }
        });
        window.addEventListener('resize',() => {
          if(window.scrollY > 180){
            let box = document.querySelector('.rail');
            self.railWidth = box.clientWidth+'px';
          }else{
            self.railWidth = '';
          }
        });
      },
      getFormattedSuit(str){
        switch(str){
          case 'Spades':
           return 's';
          case 'Hearts':
           return 'h';
          case 'Diamonds':
           return 'd';
          case 'Clubs':
           return 'c';
        }
      },
      getFormattedValue(str){
        switch(str){
          case '10':
           return 'T';
          case '11':
           return 'J';
          case '12':
           return 'Q';
          case '13':
           return 'K';
          case '14':
           return 'A';
          default:
           return str;
        }
      },
      checkPayout(type, hand, wager){
        ApiService
          .post("/payout", {
            type,
            hand,
            wager
          })
          .then(response => {
            this.toWin = response.data;
          })
          .catch(error => {
            this.$root.snackbar.message = 'We are having a problem processing your order!';
            this.$root.snackbar.open = true;
            console.log(error);
          });
      },
    },
    watch:{
      'isCartReady' (to){
        if(to){
          CanvasConfetti();
        }
      },
      'getHandTotal' (to, from){
        if(to != from){
          let type = this.getHandTotal == 3 ? 1 : 2;
          let hand = StringsService.convertToFilename(this.getPokerType);
          this.checkPayout(type,hand,this.wager);
        }
      },
      'wager' (to){
        let type = this.getHandTotal == 3 ? 1 : 2;
        let hand = StringsService.convertToFilename(this.getPokerType);
        this.checkPayout(type,hand,to);
      },
    }
  }
</script>

<style lang="scss">
    .build-hand{
      overflow-y: auto; 
      height: 100vh;
      .build-hand-title{
        font:{
          family: 'Arvo', serif;
        }
        text-shadow: 0px 4px 10px rgba(0,0,0,0.3);
        @media #{$mq-tablet-down} {
          font-size: 14px;
          .v-icon{
            display: none;
          }
        }
      }
      &.scrolled{
        position: fixed;
        top: 0px;
        width: 290px;
      }
      .v-card__subtitle{
        padding: 5px 0px;
      }
      .v-text-field__details{
        display: none !important;
      }
      .shorter{
        .v-input__control{
          .v-input__slot{
            margin-bottom: 0px !important;
          }
        }
      }
    }
</style>