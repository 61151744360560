import Vue        from 'vue';
import Vuex       from 'vuex';

Vue.use(Vuex);

import auth       from './modules/auth'
// import wallet     from './modules/wallet'
import lines       from './modules/lines'
import hand       from './modules/hand'

const store = new Vuex.Store({
  modules: {
    auth,
    lines,
    hand,
  },
});

export default store;