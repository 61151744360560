<template>
  <div class="sports-tabs">
    <v-tabs v-model="sport" :show-arrows="false">
      <v-tab v-for="(sport, index) in getSports" :key="index" :href="`#sport-${index}`">
        {{sport.accr}} [{{sportsLength(sport.accr)}}]
        <v-img :src="sport.logo" max-width="10px" max-height="24px" class="ml-4"></v-img>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="sport">
      <v-tab-item v-for="(sport, i) in getSports" :key="i" :value="'sport-' + i">
        <v-skeleton-loader
          v-if="sportsLength(sport.accr) == null"
          type="article"
        ></v-skeleton-loader>
        <v-sheet 
          v-else 
          class="px-1 py-4"
        >
          <v-expansion-panels v-model="panel[i]" mandatory multiple flat>
            <v-expansion-panel v-for="(event, index) in getSport(sport.accr,i)" :key="index">
              <v-expansion-panel-header class="pa-0 pl-5" color="#f4f4f4">
                <div class="week-header">
                  <div class="weekday">{{getDayAbbr(event.event_date)}}</div>
                  <div>{{getFullDayAndTime(event.event_date)}}</div>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <game-grid :event="event" :sport="sport"></game-grid>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-sheet>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
  import DateService from '@/services/DateService.js'
  import StringsService from '@/services/StringsService.js'
  import SportsService from '@/services/SportsService.js'
  import BettingService from '@/services/BettingService.js'

export default {
  name: 'SportsTabs.vue',
  data(){
    return{
      panel: [],
      sport: 0,
      games: [0,1,2,3],
    }
  },
  computed: {
    getSports(){
      return SportsService.getAll();
    },
    getFullNBAWeek() {
      return this.$store.getters.getFullNBAWeek;
    },
    getFullNCAAWeek() {
      return this.$store.getters.getFullNCAAWeek;
    },
    getFullNFLWeek() {
      return this.$store.getters.getFullNFLWeek;
    },
    getFullNHLWeek() {
      return this.$store.getters.getFullNHLWeek;
    },
  },
  methods:{
    sportsLength(accr){
      let data = this.getSport(accr);
      return data && data.length;
    },
    sportsAccr(){
      return SportsService.getRandom();
    },
    getRandom() {
      return StringsService.randomnumbersabs(0,5);
    },
    getDayAbbr(date){
      return DateService.getDayAbbr(date);
    },
    getFullDayAndTime(date){
      return DateService.getFullDayAndTime(date);
    },
    getDay(date){
      return DateService.getDay(date);
    },
    getRandomBetType(){
      return BettingService.getRandomBetType();
    },
    getSport(accr,i){
      let games = {};
      switch(accr){
        case "NBA":
          games = this.getFullNBAWeek;
          break;
        case "NCAA":
          games = this.getFullNCAAWeek;
          break;
        case "NFL":
          games = this.getFullNFLWeek;
          break;
        case "NHL":
          games = this.getFullNHLWeek;
          break;
      }
      let gamesArray = [];
      for (let index = 0; index < games.length; index++) {
        gamesArray.push(index);
      }
      this.panel[i] = gamesArray;
      return games;
    },
    async getUpcomingNBA() {
      let self = this;
      await this.$store
        .dispatch("getFullNBAWeek")
        .then(() => {

        })
        .catch((err) => {
          self.$root.snackbar.message = err.message;
          self.$root.snackbar.open = true;
        });
    },
    async getUpcomingNFL() {
      let self = this;
      await this.$store
        .dispatch("getFullNFLWeek")
        .then(() => {

        })
        .catch((err) => {
          self.$root.snackbar.message = err.message;
          self.$root.snackbar.open = true;
        });
    },
    async getUpcomingNHL() {
      let self = this;
      await this.$store
        .dispatch("getFullNHLWeek")
        .then(() => {

        })
        .catch((err) => {
          self.$root.snackbar.message = err.message;
          self.$root.snackbar.open = true;
        });
    },
  },
  created(){
    this.getUpcomingNBA();
    this.getUpcomingNFL();
    this.getUpcomingNHL();
  },

}
</script>

<style lang="scss">
  .sports-tabs{
    .today{
      vertical-align: middle;
      font:{
        size: 12px;
        weight: 800;
      }
    }
    .week-header{
      font:{
        family: 'Arvo', serif;
      }
      display: flex;
      align-items: center;
      color: #474747;
      .weekday{
        outline: solid 3px #474747;
        text-transform: uppercase;
        line-height: 35px;
        background-color: #fff;
        border-radius: 4px;
        margin-right: 20px;
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #474747;
        letter-spacing: 1px;
        box-sizing: content-box;
        font:{
          family: 'Raleway', serif;
          size: 13px;
        }
      }
    }
    .v-expansion-panel-header{
      font:{
        weight: bold;
      }
      .v-expansion-panel-header__icon{
        margin-right: 10px;
      }
    }
    .v-expansion-panel-content{
      .v-expansion-panel-content__wrap{
        padding: 0px;
      }
    }
    .v-tabs{
      border-bottom: solid 1px rgb(228, 228, 228);
      .v-tab{
        border: solid 1px rgb(228, 228, 228);
        border-bottom: none;
        font:{
          family: 'Arvo', serif;
        }
        &:not(:last-child){
          border-right: none;
        }
      }
    }
    .v-card{
      position: relative;
      .v-card__text{
        height: 100%;
      }
      .v-card-text-grid{
        display: grid; 
        height: 100%;
        grid-template-columns: 1fr 1fr; 
        grid-template-rows: 1fr 1fr 1fr; 
        gap: 0px 0px; 
        grid-template-areas: 
          "Card-Face Bet-Type"
          "Team Team"
          "Line-Value Game-Info"; 
          .card-face { 
            grid-area: Card-Face; 
          }
          .bet-type { 
            grid-area: Bet-Type; 
            text-align: right;
          }
          .team { 
            grid-column: 1 / 2;
            grid-area: Team;
            text-align: center;
          }
          .line-value { 
            grid-area: Line-Value; 
            text-align: left;
            align-self: end;
          }
          .game-info { 
            grid-area: Game-Info; 
            text-align: right;
            align-self: end;
          }
      }
    }
  }
</style>