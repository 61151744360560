class StringsService {
  randomstring(max,onlynumbers) {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    if(onlynumbers){
      possible = "0123456789";
    }
    for (var i = 0; i < max; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }

  randomnumbers(min,max) {
    return Math.random() * (max - min) + min;
  }

  randomnumbersabs(min,max){
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  oneorzero() {
    return (Math.random()>=0.5)? 1 : 0;
  }

  addcommas(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  ordinalSuffixOf(num) {
    const j = Number(num) % 10
    const k = Number(num) % 100;

    if (j == 1 && k != 11) {
      return 'st';
    }
    if (j == 2 && k != 12) {
      return 'nd';
    }
    if (j == 3 && k != 13) {
      return 'rd';
    }
    return 'th';
  }

  trimText(str){
    return str.replace(/\r?\n|\r/g,"").replace(/\s/g," ");
  }

  convertToFilename(str){
    return str.replace(/\r?\n|\r|\./g,"").replace(/\s/g,"-").toLowerCase();
  }

  shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  }
  
  constructor() {
    
  }
}

export default new StringsService;
