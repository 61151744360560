<template>
  <div class="header-floating">
    <v-container fluid class="pa-0">
      <v-row class="eyebrow-row ma-0" v-if="$root.user">
        <v-col cols="4" class="text-start align-self-end pa-0 pl-5 white--text">
          <span class="deep-orange--text">Sports Betting</span> + <span class="amber--text">Poker</span>
        </v-col>
        <v-col cols="8" class="text-end align-self-end pa-0">
          <v-btn-toggle class="eyebrow-menu" background-color="transparent" color="white">
            
            <v-tooltip bottom v-for="(item, index) in $root.navigation.eyebrowMenu" :key="index">
              <template v-slot:activator="{ on, attrs }">
                <v-btn x-small :ripple="false" text color="white"
                  v-bind="attrs"
                  v-on="on"
                  :to="{ name: item.name }"
                >
                  <v-icon color="#fff">{{item.icon}}</v-icon>
                </v-btn>
              </template>
              <span>{{item.title}}</span>
            </v-tooltip>

            <v-divider dark vertical></v-divider>
            
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn x-small :ripple="false" text color="white"
                  to="/my-wallet"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="#fff">mdi-wallet</v-icon>
                </v-btn>
                <span class="amount-output" v-if="getAmount">${{getAmount.amount}}</span>
              </template>
              <span>Wallet</span>
            </v-tooltip>

            <v-divider dark vertical></v-divider>

            <v-tooltip bottom v-if="$root.user">
              <template v-slot:activator="{ on, attrs }">
                <v-btn x-small :ripple="false" text color="white"
                  v-bind="attrs"
                  v-on="on"
                  to="/logout"
                >
                  <v-icon color="#fff" class="mr-2">mdi-logout</v-icon>
                </v-btn>
              </template>
              <span>Logout</span>
            </v-tooltip>

          </v-btn-toggle>
        </v-col>
      </v-row>
      <v-row class="main-row">
        <v-col md="3" sm="12" class="text-center align-self-center">
          <router-link :to="{ name: 'Home' }" class="logo">
            <img :src="require('@/assets/logo-full-simple.png')" />
          </router-link>
        </v-col>
        <v-col md="9" sm="12" class="align-self-center">
          <v-row dense>
            <v-col class="text-end justify-center">
              <pp-menu-main></pp-menu-main>
            </v-col>
            <v-col v-if="!$root.user" cols="3" class="text-end align-self-center">
              <v-btn to="/register" color="primary" x-large>Register Now</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Header.vue',
  data(){
    return{
    }
  },
  computed:{
    getAmount() {
      return this.$store.getters.getAmount;
    },
  },
  methods:{
  },

}
</script>

<style lang="scss" scoped>
  .header-floating{
    background-color: #fff;
    position: relative;
    z-index: 100;
    padding-bottom: 20px;
    .eyebrow-row{
      background-color: #1b1b1b;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      padding: 10px 0px;
    }
    .amount-output{
      line-height: 20px;
      background-color: #fff;
      color: #474747;
      border-radius: 4px;
      text-align: center;
      padding: 0px 10px;
      margin-left: 15px;
      font:{
        size: 12px;
      }
    }
    .main-row{
      margin-top: 20px;
    }
    &:before{
      content: '';
      height: 1px;
      position: absolute;
      bottom: 0px;
      left: -30px;
      right: -30px;
      background: rgb(0,0,0);
      background: -moz-linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.12) 10%, rgba(0,0,0,0.12) 90%, rgba(0,0,0,0) 100%);
      background: -webkit-linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.12) 10%, rgba(0,0,0,0.12) 90%, rgba(0,0,0,0) 100%);
      background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.12) 10%, rgba(0,0,0,0.12) 90%, rgba(0,0,0,0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
    }
    .logo{
      // display: block;
      margin-top: 8px;
      img{
        // display: block;
        width: 180px;
        // @media #{$mq-tablet-down} {
        //   width: 120px;
        // }
      }
    }
    .user-menu{
      .v-tab{
        font:{
          size: 16px;
        }
        text-transform: capitalize;
        letter-spacing: 0;
        border-radius: 10px;
        overflow: hidden;
        padding: 0 25px;
      }
    }
  }
</style>
