<template>
  <div :class="{shades: true, slotted: isSlotted}">
    <div class="slotted-content">
      <slot></slot>
    </div>
    <div class="bg"></div>
    <div class="bg bg2"></div>
    <div class="bg bg3"></div>
  </div>
</template>

<script>
export default {
  name: 'Shades.vue',
  data(){
    return{

    }
  },
  computed:{
    isSlotted(){
      return this.$slots['default'];
    },
  },
  methods:{

  }
}
</script>

<style lang="scss" scoped>
  @keyframes slide {
    0% {
      transform:translateX(-25%);
    }
    100% {
      transform:translateX(25%);
    }
  }
  .shades{
    position:absolute;
    z-index:0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 30px;
    &.slotted{
      position: relative;
    }
    .slotted-content{
      position: relative;
      z-index: 2;
      width: inherit;
      height: inherit;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #fff;
      padding: 60px;
    }
    .bg {
      animation:slide 7s ease-in-out infinite alternate;
      background-image: linear-gradient(-60deg, #607D8B 50%, #D84315 50%);
      bottom:0;
      left:-50%;
      opacity:.5;
      position:absolute;
      right:-50%;
      top:0;
      z-index:0;
    }
    .bg2 {
      animation-direction:alternate-reverse;
      animation-duration:10s;
    }
    .bg3 {
      animation-duration:15s;
    }
  }
</style>
