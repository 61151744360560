<template>
    <div :class="{'card-grid':true, 'short': short, 'mini': isMini}" color="#fff">
        {{event}}
        <header>
            <div class="bet-type">
                {{which}}
            </div>
            <div style="text-align: right; width: 40%;">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-if="showUp" x-small icon color="black" text :ripple="false" class="bet-up" @click="moveCard(id,id-1)" v-bind="attrs" v-on="on">
                            <v-icon small>mdi-chevron-up</v-icon>
                        </v-btn>
                        <span v-else><v-icon small color="#ccc">mdi-chevron-up</v-icon></span>
                    </template>
                    <span>Move Up</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-if="showDown" x-small icon color="black" text :ripple="false" class="bet-down" @click="moveCard(id,id+1)" v-bind="attrs" v-on="on">
                            <v-icon small>mdi-chevron-down</v-icon>
                        </v-btn>
                        <span v-else><v-icon small color="#ccc">mdi-chevron-down</v-icon></span>
                    </template>
                    <span>Move Down</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn x-small icon color="black" text :ripple="false" v-if="showClose" class="bet-remove" @click="removeCard(id)" v-bind="attrs" v-on="on">
                            <v-icon x-small>mdi-close</v-icon>
                        </v-btn>
                    </template>
                    <span>Remove Card</span>
                </v-tooltip>
            </div>
        </header>
        <main>
            <v-row :dense="!short">
                <v-col cols="4">
                    <game-card-symbol :cardValue="cardValue.toString()" :cardSuit="cardSuit"></game-card-symbol>
                </v-col>
                <v-col cols="4" class="text-center">
                    <v-avatar :size="isMini ? 30 : 50">
                        <img :src="convertToFileName(team.name)" class="card-bet-team">
                    </v-avatar>
                </v-col>
                <v-col cols="4">
                    <ul v-if="(which == 'spread')">
                        <li>{{getSpread()}}</li>
                        <li class="green--text">{{getSpreadMoney()}}</li>
                    </ul>
                    <ul v-if="(which == 'moneyline')">
                        <li class="green--text">{{getMoneyLine()}}</li>
                    </ul>
                    <ul v-if="(which == 'total')">
                        <li>{{getTotal()}}</li>
                        <li class="green--text">{{getTotalMoney()}}</li>
                    </ul>
                </v-col>
            </v-row>
            <v-row v-show="!short" dense>
                <v-col class="text-center">
                    <span class="team-name">
                        <span v-show="team.is_home">@</span>
                        {{team.name}}
                    </span>
                </v-col>
            </v-row>
        </main>
    </div>
</template>

<script>
    import DateService from '@/services/DateService.js'
    import StringsService from '@/services/StringsService.js'

    export default {
        name: "BuildHandCard",
        data(){
            return{
                
            }
        },
        props:{
            id:{
                type: Number,
            },
            totalCards:{
                type: Number,
            },
            cardValue:{
                type: String,
            },
            cardSuit:{
                type: Object,
            },
            team:{
                type: Object,
            },
            event:{
                type: Object,
            },
            which:{
                default: "",
                type: String,
            },
            sport:{
                type: Object,
            },
            bet:{
                type: Object,
            },
            showClose:{
                type: Boolean,
                default: true,
            },
            short:{
                type: Boolean,
                default: false,
            },
            isMini:{
                type: Boolean,
                default: false,
            }
        },
        computed:{
            showUp(){
                return (this.showClose && this.id > 0);
            },
            showDown(){
                return (this.showClose && (this.id < (this.totalCards-1)) );
            }
        },
        methods:{
            getFullDay(date){
                return DateService.standardDate(date) +"<br>"+DateService.standardTime(date);
            },
            isHome(){
                return this.team.is_home;
            },
            getCardValue(){
                return this.isHome() ? this.bet.card['home'].value : this.bet.card['away'].value;
            },
            getCardSuit(){
                return this.isHome() ? this.bet.card['home'].suit : this.bet.card['away'].suit;
            },
            getMoneyLine(){
                return this.isHome() ? this.bet.moneyline_home : this.bet.moneyline_away;
            },
            getSpread(){
                return this.isHome() ? this.bet.point_spread_home : this.bet.point_spread_away;
            },
            getSpreadMoney(){
                return this.isHome() ? this.bet.point_spread_home_money : this.bet.point_spread_away_money;
            },
            getTotal(){
                return this.isHome() ? "U " + this.bet.total_under : "O " + this.bet.total_over;
            },
            getTotalMoney(){
                return this.isHome() ? this.bet.total_under_money : this.bet.total_over_money;
            },
            convertToFileName(str){
                let sport = this.sport.accr.toLowerCase();
                try {
                return require('@/assets/'+sport+'/'+ sport+'-'+StringsService.convertToFilename(str)+'.png');
                } catch (e) {
                return this.sport.logo;
                }
            },
            moveCard(from,to){
                this.$store.dispatch("moveHand", {from,to})
            },
            removeCard(id){
                let self = this;
                this.$store
                    .dispatch("removeFromHand", id)
                    .then(() => {
                        self.$root.snackbar.message = 'Card removed from hand!';
                        self.$root.snackbar.open = true;
                    })
                    .catch((err) => {
                        self.$root.snackbar.message = err.message;
                        self.$root.snackbar.open = true;
                    });
            }
        }
    }
</script>

<style lang="scss" scoped>
    .card-grid{
        position: relative;
        // max-width: 260px;
        transition: all 0.5s linear;;
        width: calc(100% - 10px);
        margin: 5px;
        background-color: white;
        box-shadow: 0px 1px 1px rgba(0,0,0,0.3);
        border-radius: 5px;
        overflow: hidden;
        padding-top: 30px;
        &:not(:last-child){
            margin-bottom: 10px;
        }
        &.short{
            height: 100px;
            .team-name{
                display: none;
            }
        }
        &.mini{
            // max-width: 150px;
            header{
                height: 20px;
                line-height: 20px;
            }
            main{
                padding: 5px 10px;
                ul{
                    li{
                        font-size: 12px;
                    }
                }
            }
        }
        header{
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            z-index: 10;
            background-color: rgba(0,0,0,0.03);
            height: 30px;
            line-height: 30px;
            width: 100%;
            font:{
                size: 10px;
            }
            color: #272727;
            // border-top-left-radius: 20px;
            // border-top-right-radius: 20px;
            display: flex;
            flex-direction: row;
            text-transform: uppercase;
            padding: 0px 6px;
            .bet-type{
                width: 60%;
                padding-left: 6px;
            }
            .bet-remove,
            .bet-up,
            .bet-down{
                transition: all 0.2s linear;;
                width: 20px;
                height: 20px;
                line-height: 20px;
                border-radius: 5px;
                margin-left: 2px;
                i{
                    color: #000;
                }
                &:hover{
                    background-color: #000;
                    i{
                        color: #fff;
                    }
                }
            }
        }
        main{
            padding: 10px;
            color: black;
            .team-name{
                font-size: 11px;
            }
            ul{
                list-style: none;
                padding: 0px;
                margin: 0px;
                li{
                    text-align: right;
                }
            }
        }
    }
</style>