<template>
  <v-btn-toggle class="eyebrow-menu" hide-slider background-color="transparent" color="white">
    <v-btn x-small plain :ripple="false" text color="white" v-for="(item, index) in $root.navigation.eyebrowMenu"
      :key="index"
      :to="{ name: item.name }"
    >
      <v-icon color="#fff">{{item.icon}}</v-icon>
      {{item.name}}
    </v-btn>
  </v-btn-toggle>
</template>

<script>
  export default {
    name: 'Social.vue'
  }
</script>

<style lang="scss" scoped>
  .eyebrow-menu{
  }
</style>