// import ApiService from "@/services/ApiService.js";

export default {
  state:{
    hand: JSON.parse(localStorage.getItem("hand")) || [],
    bet: JSON.parse(localStorage.getItem("bet")) || [],
  },

  getters:{
    getHand(state){
      return state.hand;
    },
    getHandTotal(state){
      return state.hand.length;
    },
    getBet(state){
      return state.bet;
    },
  },
  
  mutations:{
    addToHand(state, payload){
      state.hand.push(payload);
    },
    removeFromHand(state, payload){
      state.hand.splice(payload, 1);
    },
    setHand(state, payload) {
      state.hand = payload;
    },
    setBet(state, payload) {
      state.bet = payload;
    },
    resetHand(state) {
      state.hand = [];
    },
    moveHand(state, payload) {
      var f = state.hand.splice(payload.from, 1)[0];
      state.hand.splice(payload.to, 0, f);
    },
  },

  actions:{
    addToHand(context, payload = {}){
      context.commit("addToHand", payload);
    },
    removeFromHand(context, payload = {}){
      context.commit("removeFromHand", payload);
    },
    setHand(context, payload = {}) {
      context.commit("setHand", payload);
      // localStorage.setItem("hand", JSON.stringify(payload));
    },
    setBet(context, payload = {}) {
      context.commit("setBet", payload);
      localStorage.setItem("bet", JSON.stringify(payload));
    },
    resetHand(context) {
      context.commit("resetHand", []);
      localStorage.setItem("hand", null);
    },
    moveHand(context, payload){
      context.commit("moveHand", payload);
      // localStorage.setItem("hand", null);
    }
  }
}