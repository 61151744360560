<template>
  <v-card>
    <v-card-text>
      <v-tabs
        background-color="transparent"
        color="primary"
        grow
        v-model="helpTab"
      >
        <v-tab href="#tab-3-card">3 Card Poker Hands</v-tab>
        <v-tab href="#tab-5-card">5 Card Poker Hands</v-tab>
      </v-tabs>
      <v-tabs-items v-model="helpTab">
        <v-tab-item value="tab-3-card">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Example</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Payout</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in threeCards" :key="item.name">
                  <td>
                    <div class="mini-cards">
                      <div :class="['mini-card', `card-`+card.color]" v-for="(card, index) in item.cards" :key="index">
                        <div class="card-face">
                          {{card.value}}<br>
                          <span v-html="card.symbol"></span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td><b>{{ item.name }}</b></td>
                  <td>{{ item.description }}</td>
                  <td class="green--text">+{{ item.payout }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-tab-item>
        <v-tab-item value="tab-5-card">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Example</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Payout</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in fiveCards" :key="item.name">
                  <td>
                    <div class="mini-cards">
                      <div :class="['mini-card', `card-`+card.color]" v-for="(card, index) in item.cards" :key="index">
                        <div class="card-face">
                          {{card.value}}<br>
                          <span v-html="card.symbol"></span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td><b>{{ item.name }}</b></td>
                  <td>{{ item.description }}</td>
                  <td class="green--text">+{{ item.payout }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
  import BettingService from '@/services/BettingService.js'

  export default {
    name: 'HandRankHelp.vue',
    data(){
      return{
        helpTab: 0,
        threeCards: [
          {
            cards: [
              {
                value: 3,
                symbol: BettingService.cards_symbols[0].ascii,
                color:  BettingService.cards_symbols[0].color,
              },
              {
                value: 3,
                symbol: BettingService.cards_symbols[1].ascii,
                color:  BettingService.cards_symbols[1].color,
              },
              {
                value: 7,
                symbol: BettingService.cards_symbols[3].ascii,
                color:  BettingService.cards_symbols[3].color,
              }
            ],
            name: 'ANY PAIR',
            description: 'Two of the same card, any suit.',
            payout: 10,
          },
          {
            cards: [
              {
                value: 2,
                symbol: BettingService.cards_symbols[3].ascii,
                color:  BettingService.cards_symbols[3].color,
              },
              {
                value: 5,
                symbol: BettingService.cards_symbols[3].ascii,
                color:  BettingService.cards_symbols[3].color,
              },
              {
                value: 'A',
                symbol: BettingService.cards_symbols[3].ascii,
                color:  BettingService.cards_symbols[3].color,
              }
            ],
            name: 'FLUSH',
            description: 'Three different cards of the same suit.',
            payout: 50,
          },
          {
            cards: [
              {
                value: 6,
                symbol: BettingService.cards_symbols[0].ascii,
                color:  BettingService.cards_symbols[0].color,
              },
              {
                value: 7,
                symbol: BettingService.cards_symbols[3].ascii,
                color:  BettingService.cards_symbols[3].color,
              },
              {
                value: 8,
                symbol: BettingService.cards_symbols[1].ascii,
                color:  BettingService.cards_symbols[1].color,
              }
            ],
            name: 'STRAIGHT',
            description: 'Three cards in sequential order, any suit.',
            payout: 100,
          },
          {
            cards: [
              {
                value: 4,
                symbol: BettingService.cards_symbols[1].ascii,
                color:  BettingService.cards_symbols[1].color,
              },
              {
                value: 4,
                symbol: BettingService.cards_symbols[3].ascii,
                color:  BettingService.cards_symbols[3].color,
              },
              {
                value: 4,
                symbol: BettingService.cards_symbols[0].ascii,
                color:  BettingService.cards_symbols[0].color,
              }
            ],
            name: 'THREE OF A KIND',
            description: 'Three of the same card, any suit.',
            payout: 150,
          },
          {
            cards: [
              {
                value: 'Q',
                symbol: BettingService.cards_symbols[2].ascii,
                color:  BettingService.cards_symbols[2].color,
              },
              {
                value: 'J',
                symbol: BettingService.cards_symbols[2].ascii,
                color:  BettingService.cards_symbols[2].color,
              },
              {
                value: '10',
                symbol: BettingService.cards_symbols[2].ascii,
                color:  BettingService.cards_symbols[2].color,
              }
            ],
            name: 'STRAIGHT FLUSH',
            description: 'Three consecutive cards in order all of the same suit.',
            payout: 200,
          },
          {
            cards: [
              {
                value: 'A',
                symbol: BettingService.cards_symbols[0].ascii,
                color:  BettingService.cards_symbols[0].color,
              },
              {
                value: 'K',
                symbol: BettingService.cards_symbols[0].ascii,
                color:  BettingService.cards_symbols[0].color,
              },
              {
                value: 'Q',
                symbol: BettingService.cards_symbols[0].ascii,
                color:  BettingService.cards_symbols[0].color,
              }
            ],
            name: 'ROYAL FLUSH',
            description: 'Ace, King, and Queen cards all the same suit.',
            payout: 500,
          }
        ],
        fiveCards: [
          {
            cards: [
              {
                value: 3,
                symbol: BettingService.cards_symbols[0].ascii,
                color:  BettingService.cards_symbols[0].color,
              },
              {
                value: 3,
                symbol: BettingService.cards_symbols[1].ascii,
                color:  BettingService.cards_symbols[1].color,
              },
              {
                value: 7,
                symbol: BettingService.cards_symbols[3].ascii,
                color:  BettingService.cards_symbols[3].color,
              },
              {
                value: 9,
                symbol: BettingService.cards_symbols[0].ascii,
                color:  BettingService.cards_symbols[0].color,
              },
              {
                value: 'K',
                symbol: BettingService.cards_symbols[3].ascii,
                color:  BettingService.cards_symbols[3].color,
              }
            ],
            name: 'ANY PAIR',
            description: 'Two of the same card, any suit.',
            payout: 10,
          },
          {
            cards: [
              {
                value: 5,
                symbol: BettingService.cards_symbols[3].ascii,
                color:  BettingService.cards_symbols[3].color,
              },
              {
                value: 5,
                symbol: BettingService.cards_symbols[0].ascii,
                color:  BettingService.cards_symbols[0].color,
              },
              {
                value: 9,
                symbol: BettingService.cards_symbols[1].ascii,
                color:  BettingService.cards_symbols[1].color,
              },
              {
                value: 9,
                symbol: BettingService.cards_symbols[2].ascii,
                color:  BettingService.cards_symbols[2].color,
              },
              {
                value: 'Q',
                symbol: BettingService.cards_symbols[3].ascii,
                color:  BettingService.cards_symbols[3].color,
              }
            ],
            name: 'TWO PAIR',
            description: 'Two of the same card and two of a different card, any suit.',
            payout: 40,
          },
          {
            cards: [
              {
                value: 'A',
                symbol: BettingService.cards_symbols[3].ascii,
                color:  BettingService.cards_symbols[3].color,
              },
              {
                value: 'A',
                symbol: BettingService.cards_symbols[1].ascii,
                color:  BettingService.cards_symbols[1].color,
              },
              {
                value: 'A',
                symbol: BettingService.cards_symbols[0].ascii,
                color:  BettingService.cards_symbols[0].color,
              },
              {
                value: 2,
                symbol: BettingService.cards_symbols[2].ascii,
                color:  BettingService.cards_symbols[2].color,
              },
              {
                value: 7,
                symbol: BettingService.cards_symbols[1].ascii,
                color:  BettingService.cards_symbols[1].color,
              }
            ],
            name: 'THREE OF A KIND',
            description: 'Three of the same cards, any suit.',
            payout: 60,
          },
          {
            cards: [
              {
                value: 3,
                symbol: BettingService.cards_symbols[0].ascii,
                color:  BettingService.cards_symbols[0].color,
              },
              {
                value: 4,
                symbol: BettingService.cards_symbols[1].ascii,
                color:  BettingService.cards_symbols[1].color,
              },
              {
                value: 5,
                symbol: BettingService.cards_symbols[2].ascii,
                color:  BettingService.cards_symbols[2].color,
              },
              {
                value: 6,
                symbol: BettingService.cards_symbols[3].ascii,
                color:  BettingService.cards_symbols[3].color,
              },
              {
                value: 7,
                symbol: BettingService.cards_symbols[0].ascii,
                color:  BettingService.cards_symbols[0].color,
              }
            ],
            name: 'STRAIGHT',
            description: 'Five cards in sequential order, any suit.',
            payout: 80,
          },
          {
            cards: [
              {
                value: 2,
                symbol: BettingService.cards_symbols[3].ascii,
                color:  BettingService.cards_symbols[3].color,
              },
              {
                value: 5,
                symbol: BettingService.cards_symbols[3].ascii,
                color:  BettingService.cards_symbols[3].color,
              },
              {
                value: 'A',
                symbol: BettingService.cards_symbols[3].ascii,
                color:  BettingService.cards_symbols[3].color,
              },
              {
                value: 6,
                symbol: BettingService.cards_symbols[3].ascii,
                color:  BettingService.cards_symbols[3].color,
              },
              {
                value: 9,
                symbol: BettingService.cards_symbols[3].ascii,
                color:  BettingService.cards_symbols[3].color,
              }
            ],
            name: 'FLUSH',
            description: 'Five different cards of the same suit.',
            payout: 130,
          },
          {
            cards: [
              {
                value: 3,
                symbol: BettingService.cards_symbols[0].ascii,
                color:  BettingService.cards_symbols[0].color,
              },
              {
                value: 3,
                symbol: BettingService.cards_symbols[2].ascii,
                color:  BettingService.cards_symbols[2].color,
              },
              {
                value: 8,
                symbol: BettingService.cards_symbols[1].ascii,
                color:  BettingService.cards_symbols[1].color,
              },
              {
                value: 8,
                symbol: BettingService.cards_symbols[3].ascii,
                color:  BettingService.cards_symbols[3].color,
              },
              {
                value: 8,
                symbol: BettingService.cards_symbols[2].ascii,
                color:  BettingService.cards_symbols[2].color,
              }
            ],
            name: 'FULL HOUSE',
            description: 'Two of the same card with three of the same of another card, any suit.',
            payout: 200,
          },
          {
            cards: [
              {
                value: 2,
                symbol: BettingService.cards_symbols[2].ascii,
                color:  BettingService.cards_symbols[2].color,
              },
              {
                value: 2,
                symbol: BettingService.cards_symbols[3].ascii,
                color:  BettingService.cards_symbols[3].color,
              },
              {
                value: 2,
                symbol: BettingService.cards_symbols[1].ascii,
                color:  BettingService.cards_symbols[1].color,
              },
              {
                value: 2,
                symbol: BettingService.cards_symbols[0].ascii,
                color:  BettingService.cards_symbols[0].color,
              },
              {
                value: 10,
                symbol: BettingService.cards_symbols[3].ascii,
                color:  BettingService.cards_symbols[3].color,
              }
            ],
            name: 'FOUR OF A KIND',
            description: 'Four of the same card, any suit.',
            payout: 400,
          },
          {
            cards: [
              {
                value: 7,
                symbol: BettingService.cards_symbols[2].ascii,
                color:  BettingService.cards_symbols[2].color,
              },
              {
                value: 8,
                symbol: BettingService.cards_symbols[2].ascii,
                color:  BettingService.cards_symbols[2].color,
              },
              {
                value: 9,
                symbol: BettingService.cards_symbols[2].ascii,
                color:  BettingService.cards_symbols[2].color,
              },
              {
                value: 10,
                symbol: BettingService.cards_symbols[2].ascii,
                color:  BettingService.cards_symbols[2].color,
              },
              {
                value: 'J',
                symbol: BettingService.cards_symbols[2].ascii,
                color:  BettingService.cards_symbols[2].color,
              }
            ],
            name: 'STRAIGHT FLUSH',
            description: 'Five cards in sequential order, all the same suit.',
            payout: 800,
          },
          {
            cards: [
              {
                value: 'A',
                symbol: BettingService.cards_symbols[3].ascii,
                color:  BettingService.cards_symbols[3].color,
              },
              {
                value: 'K',
                symbol: BettingService.cards_symbols[3].ascii,
                color:  BettingService.cards_symbols[3].color,
              },
              {
                value: 'Q',
                symbol: BettingService.cards_symbols[3].ascii,
                color:  BettingService.cards_symbols[3].color,
              },
              {
                value: 'J',
                symbol: BettingService.cards_symbols[3].ascii,
                color:  BettingService.cards_symbols[3].color,
              },
              {
                value: 10,
                symbol: BettingService.cards_symbols[3].ascii,
                color:  BettingService.cards_symbols[3].color,
              }
            ],
            name: 'ROYAL FLUSH',
            description: 'Ace, King, Queen, Jack, and 10, all the same suit.',
            payout: 1000,
          }
        ],
      }
    },
  }
</script>

<style lang="scss" scoped>
  a{
    text-decoration: none;
  }
  .mini-cards{
    display: flex;
    flex-direction: row;
    .mini-card{
      background-color: white;
      border-radius: 4px;
      padding: 5px;
      box-shadow: 0px 0px 5px rgba(0,0,0,0.3);
      width: 60px;
      height: 60px;
      margin-top: 5px;
      margin-bottom: 5px;
      &:not(:last-child){
        margin-right: 10px;
      }
      font:{
        size: 14px;
        weight: bold;
      }
      .card-face{
        width: 20px;
        text-align: center;
      }
      &.card-red{
        color: red;
      }
      &.card-black{
        color: #000;
      }
    }
  }
</style>