<template>
    <div>
      <v-simple-table dense fixed-header width="100%">
        <template v-slot:default>
          <thead>
            <tr>
              <th></th>
              <th class="text-center">Moneyline</th>
              <th class="text-center">Spread</th>
              <th class="text-center">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(team, index) in event.teams"
              :key="index"
            >
              <td>
                <v-avatar size="50" class="mr-2 ml-4">
                  <img :src="convertToFileName(team.name)" class="card-bet-team">
                </v-avatar>
                <small v-if="team.is_home">@</small>
                {{team.name}}
              </td>

              <td v-for="(cards, index) in event.lines['1']" :key="index">  
                <game-card :cards="cards" :which="index" :team="team" :sport="sport"></game-card>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
</template>

<script>
  import StringsService from '@/services/StringsService.js'

  export default {
    name: "Grid.vue",
    data(){
        return{
        }
    },
    props:{
        event:{
            type: Object
        },
        sport:{
            type: Object
        },
    },
    methods:{
      convertToFileName(str){
        let sport = this.sport.accr.toLowerCase();
        try {
          return require('@/assets/'+sport+'/'+ sport+'-'+StringsService.convertToFilename(str)+'.png');
        } catch (e) {
          return this.sport.logo;
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .v-data-table{
    table{
      tbody{
        tr{
          background-color: #fafafa;
          &:hover{
            background-color: #fafafa !important;
          }
          th,td{
            &:first-child{
              width: 40%;
            }
            &:not(:first-child){
              width: 20%;              
            }
          }
          td{
            padding: 0 !important;
          }
        }
      }
    }
  }
</style>