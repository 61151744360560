import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

import auth from "./middleware/auth";
import middlewarePipeline from './middleware/middlewarePipeline';

const routes = [
  { 
    path: '/',
    component: () => import('@/themes/Floating.vue'),
    children: [
      {
        name: 'Home',
        path: '/', 
        component: () => import('./views/Home.vue'),
      },
      {
        name: 'Login',
        path: '/login/:verified?',
        component: () => import('./views/auth/Login.vue'),
      },
      {
        name: 'Logout',
        path: '/logout',
        component: () => import('./views/account/Logout.vue'),
      },
      {
        name: 'ForgetPassword',
        path: '/forget-password',
        component: () => import('./views/auth/ForgetPassword.vue'),
      },
      {
        name: 'Reset',
        path: '/reset',
        component: () => import('./views/auth/NewPassword.vue'),
      },
      {
        name: 'Register',
        path: '/register',
        component: () => import('./views/auth/Register.vue'),
      },
      {
        name: 'About',
        path: '/about',
        component: () => import('./views/About.vue'),
      },
      {
        name: 'Contact',
        path: '/contact',
        component: () => import('./views/Contact.vue'),
      },
      {
        name: 'Privacy',
        path: '/privacy',
        component: () => import('./views/Privacy.vue'),
      },
      {
        name: 'TOS',
        path: '/tos',
        component: () => import('./views/TOS.vue'),
      },
      {
        name: 'Dashboard',
        path: '/dashboard', 
        component: () => import('./views/account/Dashboard.vue'),
        meta: {
          middleware: [
            auth
          ]
        },
      },
      {
        name: 'Place Bet',
        path: '/placebet', 
        component: () => import('./views/checkout/PlaceBet.vue'),
        meta: {
          middleware: [
            auth
          ]
        },
      },
      {
        name: 'Profile',
        path: '/profile', 
        component: () => import('./views/account/Profile.vue'),
        meta: {
          middleware: [
            auth
          ]
        },
      },
      {
        name: 'Bets',
        path: '/my-bets', 
        component: () => import('./views/account/MyBets.vue'),
        meta: {
          middleware: [
            auth
          ]
        },
      },
      {
        name: 'Wallet',
        path: '/my-wallet', 
        component: () => import('./views/account/MyWallet.vue'),
        meta: {
          middleware: [
            auth
          ]
        },
      },
      {
        name: 'HowToPlay',
        path: '/howtoplay', 
        component: () => import('./views/HowToPlay.vue'),
      },
    ],
  },
  { 
    path: '/',
    component: () => import('@/themes/Floating.vue'),
    children: [
      {
        name: 'Stripe: Success',
        path: '/stripe/success', 
        component: () => import('./views/stripe/Success.vue'),
        meta: {
          middleware: [
            auth
          ]
        },
      },
      {
        name: 'Stripe: Cancel',
        path: '/stripe/cancel', 
        component: () => import('./views/stripe/Cancel.vue'),
        meta: {
          middleware: [
            auth
          ]
        },
      },
    ]
  },
]

const router = new VueRouter({
	mode: 'history',
	routes,
});

router.beforeEach(async (to, from, next) => {
  await Vue.nextTick();

    if (!to.meta.middleware) {
        return next()
    }
    const middleware = to.meta.middleware
    const context = {
        to,
        from,
        next
    }
    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    })
});

export default router
