<template>
  <v-tabs class="social-menu" right hide-slider color="black" background-color="transparent" optional>
    <v-tab v-for="(item, index) in $root.navigation.socialMedia"
      :key="index"
      :href="item.url"
    >
      <v-icon>{{item.icon}}</v-icon>
    </v-tab>
  </v-tabs>
</template>

<script>
  export default {
    name: 'Social.vue'
  }
</script>

<style lang="scss" scoped>
  .social-menu{
    .v-tab{
      min-width: auto;
    }
  }
</style>