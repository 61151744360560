<template>
  <div class="wallet-widget" v-if="getAmount">    
    <v-form>
      <div>
        <v-icon color="white">mdi-wallet</v-icon>
        Wallet
      </div>
      <div>
        ${{getAmount.amount}}
      </div>
    </v-form>
  </div>
</template>

<script>
export default {
  name: 'WalletWidget.vue',
  data(){
    return{
      amount: '0',
    }
  },
  mounted(){
      
  },
  computed:{
    getAmount() {
      return this.$store.getters.getAmount;
    },
  },
  methods:{
    addFunds(){

    }
  }
}
</script>

<style lang="scss">
  .wallet-widget{
    color: #fff !important;
    text-transform: uppercase;
    padding: 8px 5px;
    // max-width: 300px;
    margin-left: auto;
    font:{
      size: 12px;
      weight: bold;
    }
    .v-icon{
      vertical-align: bottom;
    }
    .v-form{
      display: flex;
      flex-direction: row;
      >div{
        // flex-grow: 1;
        padding: 1px 20px;
        &:nth-child(1){
          text-align: center;
          line-height: 24px;
        }
        &:nth-child(2){
          line-height: 24px;
          background-color: #fff;
          color: #474747;
          border-radius: 4px;
          text-align: center;
          padding: 0px 4px;
        }
        &:nth-child(3){
          text-align: right;
          padding: 0px 4px;
          .v-btn{
            width: 100%;
            padding: 12px;
          }
        }
      }
    }
  }
</style>
