<template>
  <div>
    <v-form
      ref="form"
      v-model="form.valid"
      lazy-validation
    >
      <v-container fluid no-gutters pr-0>
        <v-row dense>
          <v-col><h4>Update Password</h4></v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-text-field
              filled
              label="New Password"
              v-model="form.fields.newPassword"
              placeholder="New Password"
              type="password"
              :rules="form.validation.newPasswordRules"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-text-field
              filled
              label="Confirm New Password"
              v-model="form.fields.newConfirm"
              placeholder="Confirm New Password"
              type="password"
              :rules="form.validation.newConfirmRules"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider class="my-5"></v-divider>
        <v-row dense>
          <v-col>
            <v-text-field
              filled
              label="Current Password"
              v-model="form.fields.password"
              placeholder="Current Password"
              type="password"
              hint="For security purposes, please enter your current password. (This does not update your password)"
              persistent-hint
              :rules="form.validation.currentRules"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col sm="12" align="right">
            <v-btn color="deep-orange darken-3" dark x-large @click.prevent="save">
              Save
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
  import ApiService from '@/services/ApiService.js';

  export default {
    name: "password",
    data() {
      return {
        form: {
          valid: true,
          validation:{
            newPasswordRules: [
              v => !!v || 'Password is required',
            ],
            /* eslint-disable no-unused-vars */
            newConfirmRules: [
              v => !!v || 'Please confirm your password',
              v => this.form.fields.newPassword === this.form.fields.newConfirm || 'Passwords must match',
            ],
            currentRules: [
              v => !!v || 'Password is required',
            ],
          },
          fields:{
            newPassword: "",
            newConfirm: "",            
            email: "",            
            password: "",            
          }
        },
      };
    },
    computed:{
      userEmail(){
        return this.$store.getters.getUser.email;
      },
    },
    created(){
      this.form.fields.email = this.userEmail;
    },
    methods: {
      save() {
        if(this.$refs.form.validate()){
          let self = this;
          ApiService
            .post(`/api/account/password`, this.form.fields)
            .then(() => {
              self.$root.snackbar.message = 'Password saved!';
              self.$root.snackbar.open = true;
            })
            .catch((err) => {
              self.$root.snackbar.message = err.message;
              self.$root.snackbar.open = true;
            })
            .finally(() => (this.loading = false));
        }
      },
    },
  };
</script>

<style lang="scss">

</style>
