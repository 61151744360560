class CardsService {
    
    bet_types = [
        'Moneyline',
        'Spread',
        'Total',
    ];

    cards_values = [
        {value: 'A'},
        {value: 'K'},
        {value: 'Q'},
        {value: 'J'},
        {value: '10'},
        {value: '9'},
        {value: '8'},
        {value: '7'},
        {value: '6'},
        {value: '5'},
        {value: '4'},
        {value: '3'},
        {value: '2'},
    ];

    cards_symbols = [
        { 
            name: 'Spades',
            color: 'black',
            ascii: '&spades;',
        },
        { 
            name: 'Hearts',
            color: 'red',
            ascii: '&hearts;',
        },
        { 
            name: 'Diamonds',
            color: 'red',
            ascii: '&diams;',
        },
        { 
            name: 'Clubs',
            color: 'black',
            ascii: '&clubs;',
        }
    ];

    getAllCardValues(){
        return this.cards_values;
    }
    getAllCardSymbols(){
        return this.cards_symbols;
    }
    getAllBetTypes(){
        return this.get_types;
    }

    getRandomBetType(){
        const rn = Math.floor(Math.random() * this.get_types.length);
        const prop = this.get_types[rn];
        return prop;
    }
    getRandomCardValue(){
        const rn = Math.floor(Math.random() * this.cards_values.length);
        const prop = this.cards_values[rn].value;
        return prop;
    }
    getRandomCardSymbol(){
        const rn = Math.floor(Math.random() * this.cards_symbols.length);
        const prop = this.cards_symbols[rn];
        return prop;
    }

    constructor() {

    }
}

export default new CardsService;