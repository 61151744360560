<template>
    <div class="filter-bar">
      <v-container fluid>
        <v-row dense>
          <v-col cols="10">
            <!-- <div class="text-overline">Currently Showing: {{getStartDate}} - {{getEndDate}}</div> -->
            <div class="text-overline">Currently Showing: 9/8/2022 to 9/12/2022 (All start times US Eastern Time)</div>
          </v-col>
          <v-col cols="2" class="text-right">
            <v-btn
              v-if="false"
              class="toggle"
              color="primary"
              small
              dark
              @click.stop="$root.filterbar.open = !$root.filterbar.open"
            >
              Filter
              <v-icon v-if="$root.filterbar.open">mdi-menu-down</v-icon>
              <v-icon v-if="!$root.filterbar.open">mdi-menu-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-expand-transition>
        <v-sheet v-show="$root.filterbar.open" color="grey lighten-3" rounded class="px-5 filter-additional-bar">
          <v-form>
            <v-container fluid>
              <v-row dense>
                <v-col cols="2">
                  <div class="text-caption text-uppercase">Search:</div>
                </v-col>
                <v-col cols="10">
                  <v-text-field
                    v-model="search"
                    placeholder="Enter keyword..."
                    required
                    solo
                    dense
                    single-line
                    class="search-field"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="2">
                  <div class="text-caption text-uppercase">Card Values:</div>
                </v-col>
                <v-col cols="10">
                  <v-btn-toggle
                    v-model="$root.filterbar.card.values"
                    multiple
                    dense
                  >
                    <v-btn dark v-for="(value, index) in getAllCardValues" x-small :key="index">{{value.value}}</v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="2">
                  <div class="text-caption text-uppercase">Card Symbols:</div>
                </v-col>
                <v-col cols="10">
                  <v-btn-toggle
                    v-model="$root.filterbar.card.symbols"
                    multiple
                    dense
                  >
                    <v-btn dark v-for="(value, index) in getAllCardSymbols" x-small :key="index" v-html="value.ascii"></v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>

              <v-row dense v-show="false">
                <v-col cols="2">
                  <div class="text-caption text-uppercase">Bet Types:</div>
                </v-col>
                <v-col cols="10">
                  <v-btn-toggle
                    v-model="$root.filterbar.bet.types"
                    multiple
                    dense
                  >
                    <v-btn dark v-for="(value, index) in getBetTypes" x-small :key="index" v-html="value"></v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col offset="2">
                  <v-btn color="green" dark small>Save</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-sheet>
      </v-expand-transition>
    </div>
</template>

<script>
  import DateService from '@/services/DateService.js'
  import BettingService from '@/services/BettingService.js'

  export default {
    name: 'Dashboard.vue',
    data(){
        return{
            filter: false,
            search: '',
        }
    },
    computed:{
      getStartDate(){ return DateService.standardDate(this.startDate) },
      getEndDate() { return DateService.standardDate(this.endDate) },
      getAllCardValues() { return BettingService.getAllCardValues() },
      getAllCardSymbols() { return BettingService.getAllCardSymbols() },
      getBetTypes() { return BettingService.getAllBetTypes() },
      fullWeek(){ return DateService.fullWeekArray() },
      startDate() { return this.fullWeek[0]; },
      endDate() { return this.fullWeek[6]; },
    }
  }
</script>

<style lang="scss">
  .filter-bar{
    .toggle{
      padding: 5px 0px 5px 8px;
    }
    .filter-additional-bar{
      position: relative;
      overflow: hidden;
      &:before{
        content: 'FILTER';
        position: absolute;
        transform: rotate(-90deg) translate(-100%);
        transform-origin: top left;
        top: 0px;
        bottom: 0px;
        left: 0px;
        height: 25px;
        line-height: 25px;
        color: rgb(150, 150, 150);
        width: 167px;
        display: block;
        text-align: center;
        text-shadow: 1px 1px 0px rgba(#ffffff,1);
        font: {
          size: 12px;
        }
      }
      .search-field{
        .v-input__control{
          .v-text-field__details{
            display: none !important;
          }
        }
      }
      .v-btn-toggle{
        .v-btn{
          background-color: rgb(172, 172, 172) !important;
          &.v-btn--active{
            background-color: #d84315 !important;
            color: #fff;
          }
        }
      }
    }
  }
</style>