<template>
  <div class="app-message">    
    <v-snackbar top dark color="deep-orange darken-3" :timeout="2000" v-model="$root.snackbar.open">
      {{ $root.snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn
        icon
          large
          plain
          v-bind="attrs"
          @click="$root.snackbar.open = false"
        >
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: 'AppMessage.vue',
  data(){
    return{

    }
  },
  mounted(){

  },
  methods:{

  }
}
</script>

<style lang="scss">
  .app-message{
    .v-snack{
      left: 0px;
      right: 0px;
      .v-snack__wrapper{
        width: 100%;
        .v-snack__content{
          font-size: 16px;
          text-align: center;
          padding: 20px;
        }
      }
    }
  }
</style>
