import Vue from 'vue';

import Shades                       from './Shades.vue';
import AgePrompt                    from './AgePrompt.vue';
import AppMessage                   from './AppMessage.vue';
import ContraTesting                from './ContraTesting.vue';
import WalletWidget                 from './WalletWidget.vue';

Vue.component('pp-shades',          Shades);
Vue.component('pp-age-prompt',      AgePrompt);
Vue.component('pp-app-message',     AppMessage);
Vue.component('pp-contra-testing',  ContraTesting);
Vue.component('pp-wallet-widget',   WalletWidget);
