class SportsService {
    list = [
        {
            name: 'Major League Baseball',
            accr: 'MLB',
            logo: require('@/assets/leagues/mlb.svg'),
            active: false,
            order: 4,
        },
        {
            name: 'Major League Soccer',
            accr: 'MLS',
            logo: require('@/assets/leagues/mls.svg'),
            active: false,
            order: 6,
        },
        {
            name: 'Nation Basketball Association',
            accr: 'NBA',
            logo: require('@/assets/leagues/nba.svg'),
            active: true,
            order: 2,
        },
        {
            name: 'National Collegiate Athletic Association',
            accr: 'NCAA',
            logo: require('@/assets/leagues/ncaa.svg'),
            active: false,
            order: 5,
        },
        {
            name: 'National Football League',
            accr: 'NFL',
            logo: require('@/assets/leagues/nfl.svg'),
            active: true,
            order: 1,
        },
        {
            name: 'National Hockey League',
            accr: 'NHL',
            logo: require('@/assets/leagues/nhl.svg'),
            active: true,
            order: 3,
        }
    ];
    
    getLogo(accr){
        if (this.list[accr] != undefined) {
            return this.list[accr]['logo'];
        }
        return "";
    }

    getRandom(){
        const keys = Object.keys(this.list);
        const rn = Math.floor(Math.random() * keys.length);
        const prop = keys[rn];
        return prop;
    }

    getAll(){
        return this.list;
    }

    sortList() {
        this.list.sort((t1,t2) => t1.order < t2.order ? -1 : 1);
    }

    showActive(){
        this.list = this.list.filter(item => item.active !== false);
    }
    
    constructor() {
        this.sortList();
        this.showActive();
    }
}

export default new SportsService;