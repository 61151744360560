<template>
    <v-menu
        bottom
        offset-y
        :disabled="isDisabled || getHandTotal == 5"
    >
        <template v-slot:activator="{ on, attrs }">
            <div :class="['card-grid',disabled]" v-bind="attrs" v-on="on">
                <div>
                    <game-card-symbol :cardValue="getCardValue().toString()" :cardSuit="getCardSuit()"></game-card-symbol>
                </div>
                <div v-if="(which == 'spread')">
                    <div>
                        <ul>
                            <li>{{getSpread()}}</li>
                            <li class="green--text">{{getSpreadMoney()}}</li>
                        </ul>
                    </div>
                </div>
                <div v-if="(which == 'moneyline')">
                    <div class="green--text">
                        <span>{{getMoneyLine()}}</span>
                    </div>
                </div>
                <div v-if="(which == 'total')">
                    <div>
                        <ul>
                            <li>{{getTotal()}}</li>
                            <li class="green--text">{{getTotalMoney()}}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </template>
        <v-list width="100%" color="#272727" dark>
            <v-list-item style="min-height: auto; margin-bottom: 10px;">
                <span class="text-body-2 text-center">Add this to your hand? 
                    <v-icon x-small>mdi-hand-front-left</v-icon>
                </span>
            </v-list-item>
            <v-list-item>
                <v-btn block dark color="primary" small @click="addToHand()">Yes</v-btn>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
    export default {
        name: "Card.vue",
        data(){
            return{
                isDisabled: false,
            }
        },
        props:{
            cards:{
                type: Object,
            },
            which:{
                default: "",
                type: String,
            },
            team:{
                type: Object,
            },
            sport:{
                type: Object,
            },
       },
        computed:{
            getHand() {
                return this.$store.getters.getHand;
            },
            getHandTotal() {
                return this.$store.getters.getHandTotal;
            },
            disabled(){
                let self = this;
                let hand = this.getHand;
                if(hand.length != 0){
                    for (let index = 0; index < hand.length; index++) {
                        const element = hand[index];
                        if(
                            element.suit.name == self.getCardSuit().name && 
                            element.value == self.getCardValue()
                        ){
                            self.isDisabled = true;
                            return 'card-disabled';
                        }else{
                            self.isDisabled = false;
                        }
                    }
                }
                if(hand.length == 0){
                    self.isDisabled = false;
                }
                return '';
            },
            isHome(){
                return this.team.is_home;
            },
        },
        methods:{
            getCardValue(){
                return this.isHome ? this.cards.card['home'].value : this.cards.card['away'].value;
            },
            getCardSuit(){
                return this.isHome ? this.cards.card['home'].suit : this.cards.card['away'].suit;
            },

            getMoneyLine(){
                return this.isHome ? this.cards.moneyline_home : this.cards.moneyline_away;
            },

            getSpread(){
                return this.isHome ? this.cards.point_spread_home : this.cards.point_spread_away;
            },
            getSpreadMoney(){
                return this.isHome ? this.cards.point_spread_home_money : this.cards.point_spread_away_money;
            },

            getTotal(){
                return this.isHome ? "U " + this.cards.total_under : "O " + this.cards.total_over;
            },
            getTotalMoney(){
                return this.isHome ? this.cards.total_under_money : this.cards.total_over_money;
            },

            addToHand(){
                let self = this;
                let params = {
                    which: this.which,
                    value: this.getCardValue(),
                    suit: this.getCardSuit(),
                    team: this.team,
                    sport: this.sport,
                    bet: this.cards,
                }
                this.$store
                    .dispatch("addToHand", params)
                    .then(() => {
                        self.$root.snackbar.message = 'Card added to hand!';
                        self.$root.snackbar.open = true;
                    })
                    .catch((err) => {
                        self.$root.snackbar.message = err.message;
                        self.$root.snackbar.open = true;
                    });
            },
        },
    }
</script>

<style lang="scss" scoped>
    .card-grid{
        position: relative;
        cursor: pointer;
        transition: all 0.5s linear;;
        display: flex;
        flex-direction: row;
        width: calc(100% - 10px);
        height: 100px;
        margin: 5px;
        background-color: white;
        box-shadow: 0px 1px 1px rgba(0,0,0,0.3);
        border-radius: 5px;
        overflow: hidden;
        &.card-disabled{
            background-color: #efefef;
            box-shadow: none;
            cursor: default;
        }
        &:before{
            transition: all 0.2s linear;
            content: '';
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            border: solid 5px transparent;
        }
        &:not(.card-disabled):hover{
            &:before{
                border: solid 5px rgba(0,0,0,0.04);
            }
        }
        &[aria-expanded="true"]{
            &:before{
                border: solid 5px #272727;
            }
        }
        >div{
            ul{
                list-style: none;
                padding: 0;
                margin: 0;
                text-align: right;
            }
            padding: 10px;
            width: 100%;
            height: 100%;;
            display: flex;
            &:first-child{
                align-items: start;
            }
            &:last-child{
                align-items: end;
                justify-content: end;
            }
        }
    }
</style>