import ApiService from "@/services/ApiService.js";

export default {
  state:{
    fullNBAWeek: {},
    fullNFLWeek: {},
    fullNHLWeek: {},
  },

  getters:{
    getFullNBAWeek(state){
      return state.fullNBAWeek;
    },
    getFullNFLWeek(state){
      return state.fullNFLWeek;
    },
    getFullNHLWeek(state){
      return state.fullNHLWeek;
    },
  },

  mutations:{
    setFullNBAWeek(state, payload) {
      state.fullNBAWeek = payload;
    },
    setFullNFLWeek(state, payload) {
      state.fullNFLWeek = payload;
    },
    setFullNHLWeek(state, payload) {
      state.fullNHLWeek = payload;
    },
  },

  actions:{
    async getFullNBAWeek(context) {
      return new Promise((resolve, reject) => {
        ApiService
          .post("/cards/get-data", {'sport': 'NBA'})
          .then(response => {
            let payload = response.data;
            context.dispatch("setFullNBAWeek", payload);
            resolve(payload.events);
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    },
    async getFullNFLWeek(context) {
      return new Promise((resolve, reject) => {
        ApiService
        .post("/cards/get-data", {'sport': 'NFL'})
        .then(response => {
            let payload = response.data;
            context.dispatch("setFullNFLWeek", payload);
            resolve(payload.events);
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    },
    async getFullNHLWeek(context) {
      return new Promise((resolve, reject) => {
        ApiService
        .post("/cards/get-data", {'sport': 'NHL'})
        .then(response => {
            let payload = response.data;
            context.dispatch("setFullNHLWeek", payload);
            resolve(payload.events);
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    },
    setFullNBAWeek(context, payload = {}) {
      context.commit("setFullNBAWeek", payload.events);
    },
    setFullNFLWeek(context, payload = {}) {
      context.commit("setFullNFLWeek", payload.events);
    },
    setFullNHLWeek(context, payload = {}) {
      context.commit("setFullNHLWeek", payload.events);
    },
  }
}