<template>
  <div>
    <v-form
      ref="form"
      v-model="form.valid"
      lazy-validation
    >
      <v-container fluid no-gutters pl-0>
        <v-row dense>
          <v-col><h4>Update Profile</h4></v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-text-field
              filled
              label="Name"
              v-model="form.fields.name"
              placeholder="Please enter your full name"
              :rules="form.validation.nameRules"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-text-field
              filled
              label="Email"
              v-model="form.fields.newEmail"
              placeholder="name@domain.com"
              :rules="form.validation.emailRules"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider class="my-5"></v-divider>
        <v-row dense>
          <v-col>
            <v-text-field
              filled
              label="Current Password"
              v-model="form.fields.password"
              placeholder="Current Password"
              type="password"
              hint="For security purposes, please enter your current password. (This does not update your password)"
              persistent-hint
              :rules="form.validation.passwordRules"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col sm="12" align="right">
            <v-btn color="deep-orange darken-3" dark x-large @click.prevent="save">
              Save
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
  import ApiService from '@/services/ApiService.js';

  export default {
    name: "Account.vue",
    data() {
      return {
        form:{
          valid: true,
          validation:{
            nameRules: [
              v => !!v || 'Name is required',
            ],
            emailRules: [
              v => !!v || 'E-mail is required',
              v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
            passwordRules: [
              v => !!v || 'Password is required',
            ],
          },
          fields:{
            name: "",
            email: "",
            password: "",
            newEmail: "",
          }
        },
      };
    },
    computed: {
      userName() {
        return this.$store.getters.getUser.name;
      },
      userEmail(){
        return this.$store.getters.getUser.email;
      },
    },
    methods: {
      save() {
        if(this.$refs.form.validate()){
          let self = this;
          ApiService
            .post(`/api/account/profile`, this.form.fields)
            .then((response) => {
              this.$store.dispatch("setUserData", self.form.fields);
              self.$root.snackbar.message = response.data.message;
              self.$root.snackbar.open = true;
              self.form.fields.email = self.form.fields.newEmail;
            })
            .catch((err) => {
              self.$root.snackbar.message = err.message;
              self.$root.snackbar.open = true;
            });
        }
      },
    },
    created(){
      this.form.fields.name = this.userName;
      this.form.fields.email = this.userEmail;
      this.form.fields.newEmail = this.userEmail;
    }
  };
</script>

<style lang="scss">

</style>
