import moment from 'moment';

class DateService {
  vuetifyDate(){
    return moment().format("YYYY-MM-DDTHH:mm");
  }

  fullDate(date){
    return moment(date).format("MMMM DD YYYY");
  }

  standardDate(date){
    return moment(date).format("MM/DD/YYYY");
  }

  standardDateMin(date){
    return moment(date).format("M/D/YYYY");
  }

  standardTime(date){
    return moment(date).format("h:mm A");
  }

  getDayAbbr(date){
    return moment(date).format("ddd");
  }

  getFullDay(date){
    return moment(date).format("dddd, MMMM DD, YYYY");
  }

  getFullDayAndTime(date){
    return moment(date).format("MMMM DD, YYYY hh:mm A");
  }

  getDay(date){
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    var d = new Date(date);
    return days[d.getDay()];
  }

  getStartDate(){
    let today = new Date();
    return today;
  }

  getEndDate(){
    let today = new Date();
    today.setDate(today.getDate() + 6);
    return today;
  }

  fullWeekArray(){
    let start = this.getStartDate();
    let end = this.getEndDate();

    for(var arr=[],dt=start; dt<=end; dt.setDate(dt.getDate()+1)){
      arr.push(new Date(dt));
    }
    return arr;
  }
  constructor() {
    
  }
}

export default new DateService;