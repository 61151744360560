import Vue from 'vue';

import Auth                         from './Auth.vue';
import Main                         from './Main.vue';
import Social                       from './Social.vue';
import Eyebrow                      from './Eyebrow.vue';

Vue.component('pp-menu-auth',        Auth);
Vue.component('pp-menu-main',        Main);
Vue.component('pp-menu-social',      Social);
Vue.component('pp-menu-eyebrow',      Eyebrow);
