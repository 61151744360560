<template>
  <v-footer class="footer-standard" app color="blue-grey darken-4" dark>
    <v-col class="text-center" cols="12">
      &copy; Copyright Parlay Poker. All Rights Reserved. 
    </v-col>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer.vue',
  data(){
    return{

    }
  },
  mounted(){

  },
  methods:{

  }
}
</script>

<style lang="scss" scoped>
  .footer-standard{

  }
</style>
