<template>
  <div>
    <v-dialog
      v-model="shouldPrompt"
      width="600"
      persistent
    >
      <form>
        <v-card class="pa-5">
          <v-card-text class="text-h5 align-self-center text-center blue-grey darken-1 white--text pa-5 rounded">
            <v-icon x-large color="white">mdi-alert-octagon</v-icon><br>Age Restricted Content
          </v-card-text>

          <v-card-text class="text-h6 text-center pa-5">
            Please select your date of birth below.
          </v-card-text>

          <v-card-actions>
            <v-row>
              <v-col>
                <v-select
                  v-model="month"
                  item-text="label"
                  item-value="id"
                  :items="setMonths"
                  label="Month"
                  dense
                  filled
                  required
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  v-model="day"
                  :items="setDays"
                  label="Day"
                  dense
                  filled
                  required
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  v-model="year"
                  :items="setYears"
                  label="Year"
                  dense
                  filled
                  required
                ></v-select>
              </v-col>
            </v-row>
          </v-card-actions>

          <v-card-actions>
            <v-row>
              <v-col class="text-center">
                By clicking continue you verify that the above information is accurate.
                <v-btn
                  x-large
                  dark
                  color="blue-grey darken-4"
                  @click="validate()"
                  class="mt-5"
                >
                  Continue
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </form>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'AgePrompt.vue',
  data(){
    return{
      month: null,
      day: null,
      year: null,
      fullDOB: null,
      formHasErrors: false,
      rules: {
        required: value => !!value || 'Required.',
      },
    }
  },
  computed:{
    setMonths(){
      return [
        {id: 1, label: 'January'}, 
        {id: 2, label: 'February'}, 
        {id: 3, label: 'March'}, 
        {id: 4, label: 'April'}, 
        {id: 5, label: 'May'}, 
        {id: 6, label: 'June'}, 
        {id: 7, label: 'July'}, 
        {id: 8, label: 'August'}, 
        {id: 9, label: 'September'}, 
        {id: 10, label: 'October'}, 
        {id: 11, label: 'November'}, 
        {id: 12, label: 'December'}
      ];
    },
    setMonthNames(){
      return this.range(1,12);
    },
    setDays(){
      return this.range(1,31);
    },
    setYears(){
      return this.range(1900,new Date().getFullYear()-21);
    },
    shouldPrompt(){
      return (this.$root.store.agePrompt && !this.isDate(this.fullDOB));
    },
    getFullDate(){
      return this.month +'/'+this.day+'/'+this.year;
    },
  },
  mounted() {
    if (localStorage.fullDOB) {
      this.fullDOB = this.getFullDate;
      this.$root.store.agePrompt = false;
    }
  },
  methods:{
    isDate(dob){
      let d = new Date(dob);
      return dob != null && d && Object.prototype.toString.call(d) === "[object Date]" && !isNaN(d);
    },
    validate() {
      let self = this;
      this.formHasErrors = false;
      if(this.month == null || this.day == null || this.year == null){
        this.formHasErrors = true;
        self.$root.snackbar.message = 'Please select your DOB so we can verify your age.';
        self.$root.snackbar.open = true;
      }else{
        this.$root.store.agePrompt = false;
        self.$root.snackbar.message = 'Thanks, Sparky!';
        self.$root.snackbar.open = true;
      }
    },
    range(start, end) {
      return Array(end - start + 1).fill().map((_, idx) => start + idx)
    },
  },
  watch: {
    getFullDate() {
      if(this.isDate(this.getFullDate)){
        localStorage.fullDOB = this.getFullDate;
      }
    }
  }
}
</script>

<style lang="scss">

</style>
