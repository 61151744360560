<template>
  <div></div>
</template>

<script>
  export default {
    data () {
      return {
        keyCode: [],
        testPass: ["ArrowUp","ArrowUp","ArrowDown","ArrowDown","ArrowLeft","ArrowRight","ArrowLeft","ArrowRight","b","a","s","s"],
      }
    },
    beforeDestroy() {
      window.removeEventListener('keydown', this.onKeyPress);
    },
    created(){
      this.$root.store.showTesting = false;
      window.addEventListener('keydown', this.onKeyPress);
    },
    methods:{
      onKeyPress(e) {
        if(e.key == 'Escape'){
          this.keyCode = [];
          return;
        }
        this.keyCode.push(e.key);
      },
    },
    watch:{
      'keyCode' (to){
        if(this.keyCode.length > 0){
          let isEqual = JSON.stringify(to) === JSON.stringify(this.testPass);
          if(isEqual){
            this.$root.store.showTesting = true;
            this.keyCode = [];
          }else{
            this.$root.store.showTesting = false;
          }
        }
      }
    },
  }
</script>
