<template>
    <div class="card-face">
        <div :class="['card-value',colorClass]" v-html="getCardValue"></div>
        <div :class="['card-symbol',colorClass]" v-html="getCardSuit"></div>
    </div>
</template>

<script>
    export default {
        name: 'CardSymbol.vue',
        props: {
            cardValue:{
                type: String
            },
            cardSuit:{
                type: Object
            },
        },
        computed:{
            colorClass(){
                if(!this.cardSuit){
                    return "";
                }
                return 'color-'+this.cardSuit.color;
            },
            getCardSuit(){
                if(!this.cardSuit){
                    return '';
                }
                return this.cardSuit.ascii;
            },
            getCardValue(){
                if(!this.cardValue){
                    return "";
                }
                switch(this.cardValue){
                    case '2':
                        return '2';
                    case '3':
                        return '3';
                    case '4':
                        return '4';
                    case '5':
                        return '5';
                    case '6':
                        return '6';
                    case '7':
                        return '7';
                    case '8':
                        return '8';
                    case '9':
                        return '9';
                    case '10':
                        return '10';
                    case '11':
                        return 'J';
                    case '12':
                        return 'Q';
                    case '13':
                        return 'K';
                    case '14':
                        return 'A';
                    default:
                        return '-';
                }
            }
        },
        methods:{
            cardValueFaceCheck(){

            }
        }
    }
</script>

<style lang="scss" scoped>
    .color-black{
        color: #000;
        .card-disabled &{
            color: #aeaeae;
        }
    }
    .color-red{
        color: red;
        .card-disabled &{
            color: #aeaeae;
        }
    }
    .card-face{
        padding: 5px 0 0 0;
        text-align: center;
        line-height: 1em;
        .card-symbol{
            width: 25px;
            font:{
                size: 20px;
            }
        }
        .card-value{
            width: 25px;
            margin-bottom: 10px;
            font:{
                size: 20px;
            }
        }
    }
</style>