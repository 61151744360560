export default function (cards) {
  let data = {};
  const errorMessage = 'Not valid'
  const FACES = ['2', '3', '4', '5', '6', '7', '8', '9', 'T', 'J', 'Q', 'K', 'A'];
  const SUITS = ['h', 'd', 'c', 's'];

  let faces = cards.map( card => FACES.indexOf(card.slice(0,-1)) );
  let suits = cards.map( card => SUITS.indexOf(card.slice(-1)) );

  if( cards.some( (card, i, self) => i !== self.indexOf(card) ) || faces.some(face => face === -1) || suits.some(suit => suit === -1) ) {
    data = {
      message: errorMessage, 
      valid: false
    };
  }

  if(cards.length < 3){
    data = {
      message: errorMessage, 
      valid: false
    };
  }

  let numCards = cards.length;
  let flush    = suits.every(suit => suit === suits[0]);
  let groups   = FACES.map( (face,i) => faces.filter(j => i === j).length).sort( (x, y) => y - x );
  let shifted  = faces.map(x => (x + 1) % 13);
  let distance = Math.min( Math.max(...faces) - Math.min(...faces), Math.max(...shifted) - Math.min(...shifted));
  let straight = groups[0] === 1 && distance < numCards;
  let facesSorted = faces.sort( (x, y) => x - y );
  let startWithTen = facesSorted[0] === 8;
  let startWithQ = facesSorted[0] === 10;

  if(numCards === 0){
    return {
      message: 'Nothing selected', 
      valid: false
    };
  }else if(numCards === 1 || numCards === 2 || numCards === 4){
    return {
      message: 'Not valid', 
      valid: false
    };
  }

  // console.log(groups);

  if ((straight && flush && startWithTen && numCards == 5) || (straight && flush && startWithQ && numCards == 3)){
    data = {
      message: 'Royal Flush', 
      valid: true
    };
  }else if (straight && flush){
    data = {
      message: 'Straight Flush', 
      valid: true
    };
  }else if (groups[0] === 4){
    data = {
      message: 'Four of a Kind', 
      valid: true
    };
  }else if (groups[0] === 3 && groups[1] === 2){
    data = {
      message: 'Full House', 
      valid: true
    };
  }else if (flush){
    data = {
      message: 'Flush', 
      valid: true
    };
  }else if (straight){
    data = {
      message: 'Straight', 
      valid: true
    };
  }else if (groups[0] === 3){
    data = {
      message: 'Three of a Kind', 
      valid: true
    };
  }else if (groups[0] === 2 && groups[1] === 2){
    data = {
      message: 'Two Pair', 
      valid: true
    };
  }else if (groups[0] === 2){
    data = {
      message: 'One Pair', 
      valid: true
    };
  }else{
    data = {
      message: errorMessage, 
      valid: false
    };
  }
  return data;
}