<template>
    <img :src="`${getLogo}`"  />
</template>

<script>
    import SportsService from '@/services/SportsService.js'

    export default {
        name: 'SportLogo.vue',
        props:{
            accr:{
                default: "",
                type: String
            },
        },
        computed:{
            getLogo(){
                return SportsService.getLogo(this.accr);
            }
        }
    }
</script>

<style lang="scss" scoped>
    img{
        max-width: 50px;
        max-height: 50px;
    }
</style>