<template>
  <div>
    <v-tabs class="main-menu" :show-arrows="false" right hide-slider optional>
      <v-tab v-for="(item, index) in $root.navigation.mainMenu"
        :key="index"
        :to="{ name: item.name }"
      >
        <v-icon color="#1b1b1b">{{item.icon}}</v-icon>
        <span class="label">{{ item.title }}</span>
      </v-tab>

      <v-tab v-if="$root.user && false" @click="logout">
        <v-icon color="#1b1b1b">mdi-logout</v-icon>
        <span class="label">Logout</span>
      </v-tab>

    </v-tabs>

    <div class="mobile-menu">
      <v-menu
        bottom
        left
        absolute
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            light
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="(item, i) in $root.navigation.mainMenu"
            :key="i"
            :to="{ name: item.name }"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Main.vue',
    methods:{
      logout(){
        let self = this;
        this.$store.dispatch("logout");
        self.$root.snackbar.message = 'Loggin out. Please wait...';
        self.$root.snackbar.open = true;
        setTimeout(function(){
          self.$router.replace('/');
        },1000);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .main-menu{
    @media #{$mq-mobile-only} {
      display: none;
    }
    .v-tab{
      min-width: auto;
      @media #{$mq-tablet-down} {
        flex-direction: column;
      }
      .label{
        font:{
          size: 12px;
        }
      }
      .v-icon{
        margin-right: 5px;
      }
    }
  }
  .mobile-menu{
    display: none;
    @media #{$mq-mobile-only} {
      display: block;
    }
  }
</style>