import ApiService from "@/services/ApiService.js";

export default {
  state:{
    user: JSON.parse(localStorage.getItem("user")) || null,
    wallet:  JSON.parse(localStorage.getItem("wallet")) || null,
  },

  getters:{
    getUser:    state => state.user,
    getUserID:  state => state.user.id,
    getAmount:  state => state.wallet
  },

  mutations:{
    setUserData(state, payload) {
      state.user = payload;
    },
    setLoginData(state, payload) {
      state.user = payload;
    },
    setAmount(state, payload) {
      state.wallet = payload;
    },
  },

  actions:{
    async login(context, params) {
      let userLogin = new Promise((resolve, reject) => {
        ApiService
          .post("/account/login", params)
          .then(response => {
            let payload = response.data;
            context.dispatch("setLoginData", payload);
            resolve(payload.user);

            new Promise((resolve, reject) => {
              ApiService
              .post("/wallet/get-amount", {
                user_id: payload.user.id
              })
              .then(response => {
                let payload = response.data;
                context.dispatch("setAmount", payload);
                resolve(payload);
              })
              .catch(error => {
                reject(error.response.data);
              });
            });
          })
          .catch(error => {
            reject(error.response.data);
          });
      });

      return Promise.all([userLogin]);
    },
    setUserData(context, payload) {
      let user = this.state.auth.user;
      user.name = payload.name;
      user.email = payload.email;
      context.commit("setUserData", user);
      localStorage.setItem("user", JSON.stringify(user));
    },
    setLoginData(context, payload = {}) {
      context.commit("setLoginData", payload.user);
      localStorage.setItem("user", JSON.stringify(payload.user));
      localStorage.setItem("token", payload.token);
    },
    refreshToken(context) {
      if(localStorage.getItem('token')){
        ApiService.post("/refresh-token").then((response) => {
          let user = response.data;
          context.dispatch("setLoginData", user);
        })
      }
    },
    logout(context) {
      context.commit("setUserData", null);
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("wallet");
      localStorage.removeItem("wager");
      localStorage.removeItem("hand");
    },
    setAmount(context, payload = {}) {
      context.commit("setAmount", payload);
      localStorage.setItem("wallet", JSON.stringify(payload));
    },
  }
}