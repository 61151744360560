<template>
  <v-app-bar class="header-standard" app light prominent dense color="white">
    <v-container>
      <v-row>
        <v-col cols="9">
          <router-link :to="{ name: 'Dashboard' }">
            <v-img :src="require('@/assets/logo-full-simple.png')" class="logo" />
          </router-link>
        </v-col>
        <v-col cols="2">
          <pp-wallet-widget></pp-wallet-widget>
        </v-col>
        <v-col cols="1" class="text-end align-self-center">
          {{user}}
          <span v-if="(user)" class="body-2">Welcome, {{user.name}}!</span>
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-app-bar-nav-icon v-bind="attrs" v-on="on"></v-app-bar-nav-icon>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in $root.navigation.userMenu"
                :key="index"
                 :to="{ name: item.name }"
              >
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
export default {
  name: 'Header.vue',
  data(){
    return{

    }
  },
}
</script>

<style lang="scss" scoped>
  .header-standard{
    width: 100%;
    .logo{
      width: 170px;
      height: 57px;
      margin-top: 8px;
    }
    .v-btn--active{
      transform: all 0.5s linear;
      background-color: #263238;
      .v-icon{
        color: #fff;
      }
    }
  }
  .v-menu__content{
    a{
      text-decoration: none;
    }
  }
</style>
