<template>
  <v-app>
    <pp-contra-testing></pp-contra-testing>
    <router-view></router-view>
  </v-app>
</template>

<script>

export default {
  name: 'App.vue',
  mounted() {
    let self = this
    setInterval(function () {
      self.$store.dispatch('refreshToken');
    }, 3000000);
  },
}
</script>

<style lang="scss">

  #app{
    background:{
      color: inherit;
    }
    .v-application--wrap{
      max-width: 1440px;
      margin: auto;
    }
  }
</style>
