<template>
  <div>
    <v-tabs class="auth-menu" right hide-slider color="black" background-color="transparent" optional>
      <v-tab 
        v-for="(item, index) in $root.navigation.userMenu"
        :key="index"
        :to="{ name: item.name }"
      >
        <v-icon>{{item.icon}}</v-icon>
        {{item.name}}
      </v-tab>
      <v-tab v-if="$root.user" @click="logout">
        <v-icon>mdi-logout</v-icon>
        Logout
      </v-tab>
    </v-tabs>
  </div>
</template>

<script>
  export default {
    name: 'Auth.vue',
    methods:{
      logout(){
        let self = this;
        this.$store.dispatch("logout");
        self.$root.snackbar.message = 'Loggin out. Please wait...';
        self.$root.snackbar.open = true;
        setTimeout(function(){
          self.$router.replace('/');
        },1000);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .auth-menu{
    .v-tab{
      min-width: auto;
      margin: 0 !important;
      width: 50%;
      font:{
        size: 12px;
      }
      .v-icon{
        margin-right: 5px;
      }
    }
  }
</style>