import Vue from 'vue';

import BuildHand                        from './BuildHand.vue';
import BuildHandTester                  from './BuildHandTester.vue';
import BuildHandCard                    from './BuildHandCard.vue';
import Card                             from './Card.vue';
import CardSymbol                       from './CardSymbol.vue';
import HandRankHelp                     from './HandRankHelp.vue';
import HandRankKeys                     from './HandRankKeys.vue';
import SportLogo                        from './SportLogo.vue';

Vue.component('game-build-hand',        BuildHand);
Vue.component('game-build-hand-tester', BuildHandTester);
Vue.component('game-build-hand-card',   BuildHandCard);
Vue.component('game-card',              Card);
Vue.component('game-card-symbol',       CardSymbol);
Vue.component('game-hand-rank-help',    HandRankHelp);
Vue.component('game-hand-rank-keys',    HandRankKeys);
Vue.component('game-sport-logo',        SportLogo);
